/**
 * The Core Module is typically used to hold singleton services, application-wide components, 
 * and other features where you want to ensure they are only loaded and created once. 
 * This is essential to prevent multiple instances of services that are supposed to be singletons.
 */
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CoreModule { 
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
