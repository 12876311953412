<mat-sidenav-container class="beyou-container">
  <!-- Side Nav -->
  <mat-sidenav #sidenav [mode]="'side'" [(opened)]="showSideMenu" class="sidenav">
    <!-- Logo -->
    <div class="logo-container">
      <a routerLink="/dashboard"><img src="/assets/svg/logo.svg" alt="Subrise"></a>
    </div>
    <!-- Menu Tree -->
    <app-side-menu></app-side-menu>
    <div class="version-info">{{ appVersion }}</div>
  
  </mat-sidenav>
<!-- Main Content -->
  <mat-sidenav-content>
    <div *ngIf="isLoading$ | async; else loadedContent" class="loader-container">
      <app-loader message="Loading the initial app data. This might take a while..."></app-loader>
    </div>
    <ng-template #loadedContent>
      <!-- The rest of your application -->
      <router-outlet></router-outlet>
    </ng-template>
  </mat-sidenav-content>
<!-- End Main Content -->
</mat-sidenav-container>