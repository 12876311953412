import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RouteNames } from './core/constants/route-names';

import {
  AuthPipe,
  AuthPipeGenerator,
  canActivate,
  customClaims,
  hasCustomClaim,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';

import { map, pipe } from 'rxjs';
import { UserRole } from './core/models/users/subrise-user.model';


const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([RouteNames.LOGIN_ROUTE]);
const redirectLoggedInToStartPage = () => redirectLoggedInTo([RouteNames.CONTENT_ROUTE]);

export const adminOnly: AuthPipeGenerator = (): AuthPipe => (pipe(
  customClaims,
  map((claims: { [key: string]: any } | null) =>
    claims && claims[UserRole.Admin] ? true : [RouteNames.LOGIN_ROUTE]
  )
) as AuthPipe);


const routes: Routes = [
  {
    path: RouteNames.AUTH_ROUTE,
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: RouteNames.USER_ACCOUNT_ROUTE,
    loadChildren: () =>
      import('./features/user-account/user-account.module').then((m) => m.UserAccountModule),
    ...canActivate(adminOnly)
  },
  {
    path: RouteNames.DASHBOARD_ROUTE,
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
    ...canActivate(adminOnly)
  },
  {
    path: RouteNames.CONTENT_ROUTE,
    loadChildren: () =>
      import('./features/content/content.module').then((m) => m.ContentModule),
    ...canActivate(adminOnly)
  },
  {
    path: RouteNames.CLIENTS_ROUTE,
    loadChildren: () =>
      import('./features/clients/clients.module').then((m) => m.ClientsModule),
    ...canActivate(adminOnly)
  },
  {
    path: RouteNames.FINANCE_ROUTE,
    loadChildren: () =>
      import('./features/finance/finance.module').then((m) => m.FinanceModule),
    ...canActivate(adminOnly)
  },
  {
    path: RouteNames.SHOP_ROUTE,
    loadChildren: () =>
      import('./features/shop/shop.module').then((m) => m.ShopModule),
    ...canActivate(adminOnly)
  },
  {
    path: '**',
    redirectTo: RouteNames.DASHBOARD_ROUTE,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
