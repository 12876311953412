import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouteNames } from './core/constants/route-names';
import { UserService } from './core/store/features/user.service';
import { CustomerService } from './core/store/features/customer.service';
import { VoiceService } from './core/store/features/voice.service';
import { AuthService } from './core/services/auth.service';
import { catchError, concatMap, finalize, firstValueFrom, forkJoin, from, Observable, of, switchMap, tap, throwError, toArray } from 'rxjs';
import { CategorizationService } from './core/store/features/categorization.service';
import { PlaceholderGroupService } from './core/store/features/placeholder-group.service';
import { PlaceholderService } from './core/store/features/placeholder.service';
import { BuildingBlockTemplateService } from './core/store/features/building-block-template.service';
import { BuildingBlockTagService } from './core/store/features/building-block-tag.service';
import { SessionTemplateService } from './core/store/features/session-template.service';
import { SmartSurveyService } from './core/store/features/smart-survey.service';
import { SmartUserProgramService } from './core/store/features/smart-user-program.service';
import { SessionService } from './core/store/features/session.service';
import { SeriesTemplateService } from './core/store/features/series-template.service';
import { ProgramTemplateService } from './core/store/features/program-template.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'beyou-app';

  isSidemenuOpen = true;

  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly customerService: CustomerService,
    private readonly categorizationService: CategorizationService,
    private readonly voiceService: VoiceService,
    private readonly placeholderGroupService: PlaceholderGroupService,
    private readonly placeholderService: PlaceholderService,
    private readonly buildingBlockTemplateService: BuildingBlockTemplateService,
    private readonly buildingBlockTagService: BuildingBlockTagService,
    private readonly sessionTemplateService: SessionTemplateService,
    private readonly smartSurveyService: SmartSurveyService,
    private readonly smartUserProgramService: SmartUserProgramService,
    private readonly sessionService: SessionService,
    private readonly seriesTemplateService: SeriesTemplateService,
    private readonly programTemplateService: ProgramTemplateService,
    private auth: AuthService
  ) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const newRoute = event.urlAfterRedirects;
        if (newRoute === `/${RouteNames.LOGIN_ROUTE}`) {
          this.isSidemenuOpen = false;
        } else {
          this.isSidemenuOpen = true;
        }
      }
    });

    this.loadData();
  }

  private async loadData(): Promise<void> {

    this.auth.user$.pipe(
      tap(user => console.log('user auth event', user)),
    ).subscribe(
      async user => {
        if (user !== null) {

          forkJoin([
            this.userService.load(),
            this.customerService.load(),
            this.categorizationService.load(),
            this.voiceService.load(),
            this.placeholderGroupService.load().pipe(
              switchMap(groups => {
                console.debug('Placeholder groups loaded', groups);
                const placeholderGroupIds = groups.map(group => group.id);
                return this.placeholderService.load(placeholderGroupIds);
              }),
              tap(placeholders => console.debug('Placeholders loaded', placeholders || []))
            ),
            this.buildingBlockTagService.load(),
            this.buildingBlockTemplateService.load(),
            this.sessionTemplateService.load(),
            this.smartSurveyService.load(),
            this.smartUserProgramService.load(),
            this.sessionService.load(),
            this.seriesTemplateService.load(),
            this.programTemplateService.load()

          ]).pipe(
            catchError(error => {
              console.error('Error occurred:', error);
              return throwError(() => error);
            }),
            finalize(() => console.log('Data loaded'))
          )
          .subscribe();

        } else {
          console.warn('User is null, clearing all data');
          this.programTemplateService.clear();
          this.seriesTemplateService.clear();
          this.sessionService.clear();
          this.smartUserProgramService.clear();
          this.smartSurveyService.clear();
          this.sessionTemplateService.clear();
          this.buildingBlockTemplateService.clear();
          this.buildingBlockTagService.clear();
          this.placeholderService.clear();
          this.placeholderGroupService.clear();
          this.voiceService.clear();
          this.categorizationService.clear();
          this.customerService.clear();
          this.userService.clear();
        }
      }
    );
  }
}
